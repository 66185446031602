<template>
  <div>
    <h1>I'm <AltName /> Brasca</h1>
    <text-carousel
      time="5000"
      class="text-carousel-1"
      prefix="I know how to use"
      :technologies="[
        'Node.JS',
        'Python',
        'TypeScript',
        'Rust',
        'C++',
        'OpenGL',
        'C#',
        'Apache Kafka',
        'Solidity',
        '.NET CORE',
        'React',
        'SignalR',
        'RabbitMQ',
        'Entity Framework',
        'Express.js',
        'ASP.NET Core',
        'Flask',
        'Postgres',
        'Kubernetes',
        'Docker',
        'AWS',
      ]"
    ></text-carousel>
    <text-carousel
      time="7000"
      class="text-carousel-2"
      prefix="I'm good at"
      :technologies="[
        'Communicating Problems',
        'Asking Questions',
        'Competitive Programming',
        'Complex Problem Solving',
        'Design Thinking',
        'Open Source',
        'System Design',
        'Teamwork',
      ]"
    ></text-carousel>
    <text-carousel
      time="3000"
      class="text-carousel-3"
      prefix="I can speak"
      :technologies="['Spanish', 'English', 'Italian']"
    ></text-carousel>
    <div class="bottom-container">
      <div class="motto">In <span>Code</span> I Trust</div>
      <div class="bottom-links">
        <a href="https://www.twitter.com/txxnano" class="talk">contact me</a>
        <a href="https://www.linkedin.com/in/ignaciobrasca/" class="talk"
          >work experience</a
        >
        <a href="https://www.github.com/Warkanlock" class="talk">portfolio</a>
        <a href="http://blog.ignaciobrasca.com" class="talk">blog</a>
      </div>
    </div>
  </div>
</template>

<script>
import AltName from "./AltName.vue";
import TextCarousel from "./TextCarousel.vue";

export default {
  name: "MainPortfolio",
  components: {
    AltName,
    TextCarousel,
  },
};
</script>

<style scoped>
.talk {
  font-weight: bold;
  display: inline-block;
  padding: 5px;
  font-size: 20px;
  margin-bottom: 20px;
  color: #0c050e;
}
.talk:hover {
  filter: invert();
}
.text-carousel-1 {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-carousel-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-carousel-3 {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translate(-50%, -50%);
}
h1 {
  color: #faffff;
  font-size: 44px;
  letter-spacing: 10px;
}
.motto {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #0c050e;
  letter-spacing: 5px;
  transition: all 0.5s;
}
.motto:hover {
  filter: invert();
  transition: all 0.5s;
  bottom: 5%;
}
.bottom-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.bottom-links {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

span {
  display: inline-block;
  margin: 0 10px;
  color: white;
}
</style>
